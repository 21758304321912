// Import the functions you need from the SDKs you need
import * as firebase from "firebase/app";
import { getMessaging, isSupported } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDcfEVF9HioohqDJKA3oNULbEoCmwsU_00",
  authDomain: "tacos-locos-9842c.firebaseapp.com",
  projectId: "tacos-locos-9842c",
  storageBucket: "tacos-locos-9842c.firebasestorage.app",
  messagingSenderId: "1070010219892",
  appId: "1:1070010219892:web:364601ce79cd7b198c8021",
  measurementId: "G-Q53937XH7Q",
};

export const initialize = () => {
  // Initialize Firebase
  const app = firebase.initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  return messaging;
};

export const isFirebaseSupported = async () => {
  return await isSupported();
};
