import {
  Box,
  Divider,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useTranslation } from "react-i18next";

import useStyles from "./styles";

function Footer() {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();

  const redirectHandler = (link) => {
    window.open(link, "_blank");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleButtonClick = () => {
    try {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } catch (error) {
      console.error("Smooth scroll failed", error);
      // Fallback to instant scroll
      window.scrollTo(0, 0);
    }
  };

  return (
    <Grid container alignItems="center">
      <Grid
        item
        xs={12}
        md={2.5}
        align="center"
        style={{ padding: small ? "3rem" : 0 }}
      >
        <Box className={classes.left}>
          <Typography
            variant="h4"
            style={{
              fontWeight: 900,
              // color: theme.palette.success.light,
              color: "#B13434",
              marginBottom: 20,
            }}
            align="center"
          >
            Tacos Locos
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontWeight: 500,
              color: theme.palette.common.white,
              fontSize: 15,
            }}
          >
            {t("footerText")}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Footer;
