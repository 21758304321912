const de = {
  translation: {
    selectLanguage: "Sprache auswählen",
    saveButton: "speichern",
    footerText:
      "Bij Tacos Locos in St. Louis bereiden we voor iedereen lekkere maaltijden. Voor een kleine of grote honger, hier vindt u iets om u mee te plezieren! Wij nodigen u uit om onze menu's met taco's, pizza's, sandwiches, hamburgers, borden en desserts te raadplegen, voor het plezier van de ogen vóór het plezier van de smaak! Wij hechten waarde aan kwaliteitsproducten voor een unieke culinaire ervaring!",
    footerLinkHome: "Startseite",
    footerLinkPP: "Datenschutzbestimmungen",
    footerLinkTC: "Geschäftsbedingungen",
    footerEndText: "Tacos Locos  - © 2024 Alle Rechte vorbehalten",
    followUs: "Folge uns",
    poweredBy: "Angetrieben von",
    linksTitle: "Verknüpfungen",
    subTotal: "Zwischensumme",
    deliveryFee: "Liefergebühr",
    haveVoucher: "Hast du einen Gutschein?",
    remove: "Entfernen",
    change: "Veränderung",
    taxFee: "Steuerbelastungen",
    total: "Gesamt",
    tip: "Tipp",
    discount: "Rabatt",
    name: "Name",
    phone: "Telefon",
    email: "Email",
    address: "Adresse",
    changeAddress: "Adresse ändern",
    contactInfo: "Kontaktinformationen",
    paymentMethod: "Zahlungsmethode",
    orderBtn: "Bestellung aufgeben",
    deliveryTime: "Lieferzeit",
    delivery: "Lieferung",
    searchRestaurantPlaceholder: "Vul uw adres in, bedankt!",
    allRestaurant: "Alle Restaurants",
    pending: "Ausstehend",
    accepted: "Akzeptiert",
    assigned: "Zugewiesen",
    picked: "Abgeholt",
    delivered: "Geliefert",
    completed: "Abgeschlossen",
    titleOrders: "meine Bestellungen",
    titleSettings: "Einstellungen",
    titleProfile: "Profiel",
    titleHelp: "Hilfe",
    titleChat: "Plaudern",
    titleLogout: "Ausloggen",
    passwordInfo: "Passwortinformationen",
    myAddresses: "Meine Adressen",
    edit: "Bearbeiten",
    creditDebitCard: "Kredit- / Debitkarte",
    paypal: "Paypal",
    cash: "Bargeld",
    deliverTo: "Liefern an",
    ASSIGNED: "zugewiesen",
    PENDING: "ausstehend",
    PICKED: "abgeholt",
    ACCEPTED: "akzeptiert",
    DELIVERED: "geliefert",
    COMPLETED: "abgeschlossen",
    orderPlaced: "Bestellung aufgegeben",
    "Order ID": "Auftragsnummer",
    "Order status: PENDING": "Bestellstatus: AUSSTEHEND",
    "Order status: ACCEPTED": "Bestellstatus: AKZEPTIERT",
    "Order status: ASSIGNED": "Bestellstatus: ZUGEWIESEN",
    "Order status: PICKED": "Bestellstatus: AUSGEWÄHLT",
    "Order status: DELIVERED": "Bestellstatus: GELIEFERT",
    "Order status: Order Placed": "Bestellstatus: Bestellung aufgegeben",
    deliveryAddress: "Lieferadresse",
    orderDetail: "Bestelldetails",
    orderFrom: "Ihre Bestellung von",
    orderNo: "Ihre Bestellnummer",
    findRestaurants: "Valideren",
    putUsInYourPocket: "Steck uns in deine Tasche.",
    containerText:
      "Alles liegt in deiner Handfläche – die Restaurants, die du liebst. Finde das richtige Essen, um deine Stimmung zu treffen, und mache den ersten Bissen zum Erlebnis. Los, lade uns herunter.",
    findingAddress: "Adresse finden mit GPS-Integration",
    zonesFunctionality: "Zonenfunktion für Restaurants und Fahrer",
    multipleRestaurants: "Funktion zum Hinzufügen mehrerer Restaurants",
    realTimeOrder: "Echtzeitaktualisierungen zum Bestellempfang",
    differentSections:
      "Funktion für verschiedene Abschnitte zur Förderung von Restaurants",
    previousOrder:
      "Vorherige Bestellhistorie und Hinzufügen von Lieblingsrestaurants",
    ourDelivery: "Unser Liefersystem ist für die Zukunft konzipiert",
    builtOnCommunity: "Auf gemeinschaftsgetriebenen Prinzipien aufgebaut",
    yourCart: "Ihr Warenkorb",
    startAdding: "Beginnen Sie damit, Artikel in Ihren Warenkorb zu legen",
    goToCheckout: "ZUR KASSE GEHEN",
    activeOrders: "Aktive Bestellungen",
    pastOrders: "Vergangene Bestellungen",
    deliveringTo: "Lieferung an",
    restaurantDeliver:
      "Wir fragen das Restaurant, wie lange es dauern wird, um Ihr Essen zu liefern",
    riderDeliver:
      "Wir fragen den Fahrer, wie lange es dauern wird, um Ihr Essen zu liefern",
    orderPending: "Ihre Bestellung ist noch ausstehend",
    areYouSure: "Bist du sicher?",
    clearCartText:
      "Durch den Wechsel des Restaurants werden die Artikel in deinem Warenkorb gelöscht",
    ok: "OK",
    cancel: "Abbrechen",
    submit: "einreichen",
    titleFavourite: "Favoritin",
    titleEmptyFav: "Keine Favoriten gespeichert",
    emptyFavDesc:
      "Hier sehen Sie alle Ihre Favoriten, um die Bestellung noch schneller zu machen.",
    emptyFavBtn: "Lassen Sie uns einige Favoriten finden",
    exactLocation: "Ist dies dein genauer Standort?",
    yourArea: "Gib deine Region ein",
    currentLocation: "Aktuellen Standort verwenden",
    deliveryDetails: "Erforderliche Angaben, z. Boden / Gebäude",
    deliveryDetailError: "Lieferdetails sind erforderlich",
    currentPassword: "Aktuelles Passwort",
    newPassword: "Neues Passwort",
    deliver: "Lieferung",
    time: "Zeit",
    pickup: "Abholung",
    selectTime: "Zeit auswählen",
    am: "vormittags",
    pm: "nachmittags",
    set: "Einstellen",
    apply: "Anwenden",
    applyAVoucher: "Gutschein anwenden",
    readAll: "Alles lesen",
    readMore: "Mehr lesen",
    demo: "Demo",
    getQuote: "Angebot erhalten",
    restaurantCloseText:
      "Tacos Locos is momenteel gesloten. Ontdek het menu om uw volgende maaltijd te plannen",
    seeOtherRestaurants: "ANDERE RESTAURANTS ANZEIGEN",
    goToMenu: "Zur Speisekarte",
    deliveryHours: "Lieferzeiten",
    about: "ÜBER",
    reviews: "BEWERTUNGEN",
    closedAllDay: "Den ganzen Tag geschlossen",
    minute: "Minutes",
    welcome: "Willkommen!",
    signUpOrLogin: "Registrieren oder anmelden, um fortzufahren",
    signInWithGoogle: "MIT GOOGLE FORTFAHREN",
    or: "oder",
    continueWithEmail: "MIT E-MAIL FORTFAHREN",
    terms: "Allgemeine Geschäftsbedingungen",
    privacyPolicy: "Datenschutzrichtlinie",
    bySigningUp: "Durch die Registrierung stimmst du unseren ",
    and: " und ",
    whatsYourEmail: "Was ist deine E-Mail-Adresse?",
    checkAccount: "Wir überprüfen, ob du ein Konto hast",
    continue: "Weiter",
    invalidEmail: "Ungültige E-Mail-Adresse",
    letsGetStarted: "Lass uns anfangen!",
    createAccount: "Erstelle dein Tacos Locos -Konto",
    firstnameErr1: "Vorname ist erforderlich!",
    firstnameErr2: "Ungültiger Vorname!",
    lastnameErr1: "Nachname ist erforderlich!",
    lastnameErr2: "Ungültiger Nachname!",
    mobileErr1: "Telefonnummer ist erforderlich!",
    mobileErr2: "Ungültige Telefonnummer!",
    emailErr2: "Ungültige E-Mail-Adresse",
    passwordErr1:
      "Ungültiges Passwort. Das Passwort muss mindestens 1 Großbuchstaben, 1 Kleinbuchstaben und 1 Zahl enthalten.",
    passwordErr2: "Ungültiges Passwort",
    generalErr: "Etwas fehlt",
    verifyEmail: "Bestätigen Sie Ihre E-Mail",
    enterOtp:
      "Bitte geben Sie den OTP ein, den wir an Ihre aktualisierte E-Mail gesendet haben",
    enterOtpPhone:
      "Bitte geben Sie den OTP ein, den wir an Ihre aktualisierte Telefonnummer gesendet haben",
    invalidCode: "Ungültiger Code, bitte überprüfen und erneut eingeben",
    resendCode: "Code erneut senden",
    retryAfter: "Erneuter Versuch nach",
    loginBtn: "Anmeldung",
    addToCart: "in den Warenkorb legen",
    customize: "Anpassen",
    optional: "Optional",
    required: "Erforderlich",
    specialInstructions: "Besondere Anweisungen",
    anySpecific:
      "Irgendwelche spezifischen Vorlieben? Lassen Sie es das Restaurant wissen.",
    selectVariation: "Variation auswählen",
    closed: "Geschlossen",
    new: "NEU",
    orderCancelled: "Ihre Bestellung wurde storniert",
    orderCompleted:
      "Bestellung erfolgreich abgeschlossen. Vielen Dank für Ihre Bestellung",
    reorder: "NEUBESTELLEN",
    review: "Überprüfung",
    subTitle: "Auswahl 1",
    placeholder: "z.B. Keine Mayo",
    phoneNumMissing: "Telefonnummer fehlt",
    updatePhone: "Aktualisieren Sie Ihr Telefon",
    number: "Nummer?",
    secureAcc: "Wir brauchen das, um Ihr Konto zu sichern",
    verifyPhone: "Überprüfen Sie Ihr Telefon",
    skipNow: "Jetzt überspringen",
    favouriteListUpdated: "Favoritenliste aktualisiert",
    pendingText: "Warten auf Antwort von",
    acceptedText: "Geschätzte Zubereitungszeit",
    orderLateText: "Entschuldigung! Ihre Bestellung kommt etwas spät.",
    riderPickText: "Ihr Fahrer wird es abholen, sobald es bereit ist.",
    orderIs: "Ihre Bestellung ist",
    orderAssigned: "Dem Fahrer zugewiesen",
    orderAssignedToRider:
      "Ihre Bestellung wurde unserem Fahrer zugewiesen und wird abgeholt, sobald sie bereit ist.",
    riderOnWay: "Ihr Fahrer ist unterwegs.",
    orderHasBeen: "Ihre Bestellung wurde",
    enjoyYourMeal: "Guten Appetit!",
    cancelled: "Abgebrochen",
    personalDetail: "Persönliche Angaben",
    voucherCode: "Gutscheincode",
    pickUp: "Abholung",
    warning: "Warnung",
    warningText: "Ein oder mehrere Elemente sind nicht verfügbar",
  },
};
export default de;
