import moment from "moment";
import { DAYS } from "./constantValues";

function isValidEmailAddress(address) {
  return !!address.match(/.+@.+/);
}

const cartItemQuantity = (items) => {
  return items
    .map(
      (item) =>
        `${item.quantity}x ${item.title}${
          item.variation.title ? `(${item.variation.title})` : ""
        }`
    )
    .join("\n");
};

function calculatePrice(food) {
  var foodPrice = food.variation.price;
  food.addons.forEach((addons) => {
    addons.options.forEach((addon) => {
      foodPrice += addon.price;
    });
  });
  return foodPrice;
}

function calculateDistance(latS, lonS, latD, lonD) {
  var R = 6371; // km
  var dLat = toRad(latD - latS);
  var dLon = toRad(lonD - lonS);
  var lat1 = toRad(latS);
  var lat2 = toRad(latD);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
}

// Converts numeric degrees to radians
function toRad(Value) {
  return (Value * Math.PI) / 180;
}

function calculateAmount(costType, deliveryRate, distance) {
  return costType === "fixed"
    ? deliveryRate
    : Math.ceil(distance) * deliveryRate;
}

const restaurantIsOpen = ({ openingTimes }) => {
  if (openingTimes?.length < 1) {
    return false;
  }
  const date = new Date();
  const day = date.getDay();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const month = date.getMonth();
  const year = date.getFullYear();
  const todaysTimings = openingTimes?.find((o) => o.day === DAYS[day]);
  if (todaysTimings === undefined) {
    return false;
  }
  const times = todaysTimings.times.filter((t) => {
    // ########## old Algorithm ##################
    /* // hours = 23
      // minutes = 41
      const startTimeHour = Number(t.startTime[0])
      const startTimeMinutes = Number(t.startTime[1])
      const endTimeHours = Number(t.endTime[0])
      const endTimeMinutes = Number(t.endTime[1])
      const exceedStart = hours === startTimeHour && minutes >= startTimeMinutes
      const realHour = hours === 0 ? 24 : hours
      const realEndHour =
        endTimeHours < startTimeHour ? endTimeHours + 24 : endTimeHours
      console.log(
        'hours : ',
        hours,
        'minutes : ',
        minutes,
        'exceedStart ',
        exceedStart,
        'realHour ',
        realHour,
        'realEndHour ',
        realEndHour,
        'endTimeMinutes ',
        endTimeMinutes,
        realHour * 60 + minutes,
        realEndHour * 60 +
          endTimeMinutes -
          (data?.restaurant.deliveryTime + 10),
        // hours === startTimeHour && minutes >= startTimeHour
        // t.endTime[0] < t.startTime[0],
        'is open ',
        exceedStart ||
          (realHour <= realEndHour &&
            hours > startTimeHour &&
            realHour * 60 + minutes <=
              realEndHour * 60 +
                endTimeMinutes -
                (data?.restaurant.deliveryTime + 10))
      )
      return (
        exceedStart ||
        (realHour <= realEndHour &&
          hours > startTimeHour &&
          realHour * 60 + minutes <=
            realEndHour * 60 +
              endTimeMinutes -
              (data?.restaurant.deliveryTime + 10))
      ) */
    // ########## old Algorithm ##############
    // hours = 23
    // minutes = 41
    const startTimeHour = t.startTime[0];
    const startTimeMinutes = t.startTime[1];
    const endTimeHours = t.endTime[0];
    const endTimeMinutes = t.endTime[1];

    const dateNow = `${year}-${month}-${day}`;
    const dateFormat = "YYYY-MM-DD HH:mm";

    const timeNow = moment(`${dateNow} ${hours}:${minutes}`, dateFormat).unix();

    const startingTime = moment(
      `${dateNow} ${startTimeHour}:${startTimeMinutes}`,
      dateFormat
    ).unix();
    let endingTime = moment(
      `${dateNow} ${endTimeHours}:${endTimeMinutes}`,
      dateFormat
    );
    if (Number(endTimeHours) < Number(startTimeHour)) {
      endingTime = endingTime.add({ days: 1 }).unix();
    } else {
      endingTime = endingTime.unix();
    }

    /* console.log(
      "hours : ",
      hours,
      "minutes : ",
      minutes,
      "timeNow ",
      timeNow,
      "startingTime ",
      startingTime,
      "endingTime ",
      endingTime,
      "is open ",
      timeNow >= startingTime && timeNow <= endingTime
    ); */
    return timeNow >= startingTime && timeNow <= endingTime;
  });
  return true;
  return times?.length > 0;
};

export {
  isValidEmailAddress,
  cartItemQuantity,
  calculatePrice,
  calculateDistance,
  calculateAmount,
  restaurantIsOpen,
};
