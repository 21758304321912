const en = {
  translation: {
    selectLanguage: "Select Language",
    saveButton: "Save",
    footerText:
      "At Tacos Locos in St. Louis, we prepare tasty meals for everyone. For a little or a big hunger, you will find something to delight you with here! We invite you to consult our menus of tacos, pizzas, sandwiches, burgers, plates and desserts, for the pleasure of the eyes before the pleasure of taste! We value quality products for a unique culinary experience!",
    footerLinkHome: "Home",
    footerLinkPP: "Privacy Policy",
    footerLinkTC: "Terms and Conditions",
    footerEndText: "Tacos Locos  – © 2024 All Rights Reserved",
    followUs: "Follow Us",
    poweredBy: "Powered By",
    linksTitle: "Links",
    subTotal: "SubTotal",
    deliveryFee: "Delivery Fee",
    haveVoucher: "Do you have a voucher ?",
    remove: "Remove",
    change: "Change",
    taxFee: "Tax Charges",
    total: "Total",
    tip: "Tip",
    discount: "Discount",
    name: "Name",
    phone: "Phone",
    email: "Email",
    address: "Address",
    changeAddress: "Change Address",
    contactInfo: "Contact Information",
    paymentMethod: "Payment Method",
    orderBtn: "Place order",
    deliveryTime: "Delivery Time",
    delivery: "Delivery",
    searchRestaurantPlaceholder: "Please enter your address, Thank you!",
    allRestaurant: "All Restaurants",
    pending: "PENDING",
    accepted: "ACCEPTED",
    assigned: "ASSIGNED",
    picked: "PICKED",
    delivered: "DELIVERED",
    completed: "COMPLETED",
    titleOrders: "My Orders",
    titleProfile: "Profile",
    titleSettings: "Settings",
    titleHelp: "Help",
    titleChat: "Chat",
    titleLogout: "Logout",
    passwordInfo: "Password Info",
    myAddresses: "My Addresses",
    edit: "Edit",
    creditDebitCard: "Credit / Debit Card",
    paypal: "Paypal",
    cash: "Cash",
    deliverTo: "Deliver to",
    ASSIGNED: "ASSIGNED",
    PENDING: "PENDING",
    PICKED: "PICKED",
    ACCEPTED: "ACCEPTED",
    DELIVERED: "DELIVERED",
    COMPLETED: "COMPLETED",
    orderPlaced: "Order Placed",
    "Order ID": "Order ID",
    "Order status: PENDING": "Order status: PENDING",
    "Order status: ACCEPTED": "Order status: ACCEPTED",
    "Order status: ASSIGNED": "Order status: ASSIGNED",
    "Order status: PICKED": "Order status: PICKED",
    "Order status: DELIVERED": "Order status: DELIVERED",
    "Order status: Order Placed": "Order status: Order Placed",
    deliveryAddress: "Delivery Address",
    orderDetail: "Order Detail",
    orderFrom: "Your Order From",
    orderNo: "Your Order No.",
    findRestaurants: "Validate",
    putUsInYourPocket: "Put us in your pocket",
    containerText:
      "It's all at your fingertips -- the restaurants you love. Find the right food to suit your mood, and make the first bite last. Go ahead, download us.",
    findingAddress: "Finding address using GPS integration",
    zonesFunctionality: "Zones functionality for Restaurants and Riders",
    multipleRestaurants: "Multiple Restaurant  adding feature",
    realTimeOrder: "Real-time order receiving updates",
    differentSections: "Different sections feature for promoting restaurants",
    previousOrder: "Previous order history and adding favorite restaurants",
    ourDelivery: "Our delivery management system is designed for the future.",
    builtOnCommunity: "Built on community-driven principles.",
    yourCart: "Your Cart",
    startAdding: "Start adding items to your cart",
    goToCheckout: "GO TO CHECKOUT",
    activeOrders: "ACTIVE ORDERS",
    pastOrders: "PAST ORDERS",
    deliveringTo: "DELIVERING TO",
    restaurantDeliver:
      "We are asking the restaurant how long it will take to deliver your food",
    riderDeliver:
      "We are asking the rider how long it will take to deliver your food",
    orderPending: "Your order is still pending",
    areYouSure: "Are you sure?",
    clearCartText:
      "By changing restaurant, the items you`ve added to cart will be cleared",
    ok: "OK",
    cancel: "CANCEL",
    submit: "Submit",
    titleFavourite: "Favourites",
    titleEmptyFav: "No Favourites Saved",
    emptyFavDesc:
      "You'll see all your favourites here, to make ordering even faster.",
    emptyFavBtn: "Let's find some favourites",
    exactLocation: "Is this your exact location?",
    yourArea: "Enter Your Area",
    currentLocation: "Use current location",
    deliveryDetails: "Required details -e.g. floor/ building",
    deliveryDetailError: "Delivery details is required",
    currentPassword: "Current Password",
    newPassword: "New Password",
    deliver: "Delivery",
    time: "Time",
    pickup: "Pickup",
    selectTime: "Select Time",
    am: "AM",
    pm: "PM",
    set: "Set",
    apply: "Apply",
    applyAVoucher: "Apply a voucher",
    readAll: "Read All",
    readMore: "Read More",
    demo: "Demo",
    getQuote: "Get Quote",
    restaurantCloseText:
      "Tacos Locos is currently closed. Discover the menu to plan your next meal.",
    seeOtherRestaurants: "SEE OTHER RESTAURANTS",
    goToMenu: "Go To Menu",
    deliveryHours: "Delivery hours",
    about: "ABOUT",
    reviews: "REVIEWS",
    closedAllDay: "Closed all day",
    minute: "Minutes",
    welcome: "Welcome!",
    signUpOrLogin: "Sign up or log in to continue",
    signInWithGoogle: "CONTINUE WITH GOOGLE",
    or: "or",
    continueWithEmail: "CONTINUE WITH EMAIL",
    terms: "Terms and Conditions",
    privacyPolicy: "Privacy Policy",
    bySigningUp: "By signing up, you agree to our ",
    and: " and ",
    whatsYourEmail: "What's your Email?",
    checkAccount: "We'll check if you've an account",
    continue: "Continue",
    invalidEmail: "Invalid Email",
    letsGetStarted: "Let's get started!",
    createAccount: "Create your Tacos Locos  Account",
    firstnameErr1: "Firstname is required!",
    firstnameErr2: "Invalid First Name!",
    lastnameErr1: "Lastname is required!",
    lastnameErr2: "Invalid Last Name!",
    mobileErr1: "Phone number is required!",
    mobileErr2: "Invalid Phone number",
    emailErr2: "Invalid Email",
    passwordErr1:
      "Invalid Password. Password must contain at least 1 capital letter, 1 small letter, and 1 number.",
    passwordErr2: "Invalid Password",
    generalErr: "Something is missing",
    verifyEmail: "Verify your email",
    enterOtp: "Please enter the OTP we sent to your updated email",
    enterOtpPhone: "Please enter the OTP we sent to your updated Phone Number",
    invalidCode: " Invalid code, please check and enter again",
    resendCode: "Resend code",
    retryAfter: "Retry after",
    loginBtn: "Login",
    addToCart: "Add to Cart",
    customize: "Customize",
    optional: "OPTIONAL",
    required: "REQUIRED",
    specialInstructions: "Special Instructions",
    anySpecific: "Any specific preferances? Let the restaurant know.",
    selectVariation: "Select Variation",
    closed: "Closed",
    new: "NEW",
    orderCancelled: "Your order has been cancelled",
    orderCompleted: "Order completed successfully. Thankyou for placing order",
    reorder: "REORDER",
    review: "Review",
    subTitle: "Select 1",
    placeholder: "e.g. No mayo",
    phoneNumMissing: "Phone Number is missing",
    updatePhone: "Update your phone",
    number: "number?",
    secureAcc: "We need this to secure your account",
    verifyPhone: "Verify your phone",
    skipNow: "Skip now",
    favouriteListUpdated: "Favourite list updated",
    pendingText: "Waiting response from",
    acceptedText: "Estimated preparation time",
    orderLateText: "Sorry! Your order is bit late.",
    riderPickText: "Your rider will pick it up once its ready",
    orderIs: "Your order is",
    orderAssigned: "assigned to the rider",
    orderAssignedToRider:
      "Your order is assigned to our rider, order will be picked once its ready.",
    riderOnWay: "Your rider is on the way.",
    orderHasBeen: "Your order has been",
    enjoyYourMeal: "Enjoy your meal!",
    cancelled: "Cancelled",
    personalDetail: "Personal Detail",
    voucherCode: "Voucher code",
    pickUp: "Pickup",
    warning: "warning",
    warningText: "One or more item is not available",
  },
};
export default en;
