import { Box, Container, Typography, useTheme } from "@mui/material";
import StarSharpIcon from "@mui/icons-material/StarSharp";
import clsx from "clsx";
import React from "react";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { restaurantIsOpen } from "../../../utils/customFunction";

function RestaurantHeader({ headerData, loading = false }) {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const isClosed =
    !restaurantIsOpen({ openingTimes: headerData?.openingTimes }) ||
    !headerData.isAvailable;

  return (
    <Box>
      <Container style={{ marginLeft: "0px" }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "15px",
          }}
        >
          <Typography className={classes.restaurantTitle} align="center">
            {headerData?.name ?? "..."}
          </Typography>
        </Box>
      </Container>
      {!loading && (
        <>
          {/* <Container
            style={{
              marginLeft: "0px",
              display: "flex",
              marginTop: 15,
              justifyContent: "center",
            }}
          >
            <Box
              className={clsx({
                [classes.tagContainer]: !isClosed,
                [classes.closeContainer]: isClosed,
              })}
            >
              <Typography
                className={clsx({
                  [classes.tagStyles]: !isClosed,
                  [classes.closeTag]: isClosed,
                })}
              >
                {true ? t("new") : t("closed")}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "15px",
              }}
            >
              <StarSharpIcon
                style={{
                  fontSize: "16px",
                  color: theme.palette.common.white,
                  marginRight: 5,
                }}
              />
              <Typography className={classes.currentRatingText} align="center">
                {headerData.averageReview}
              </Typography>
              <Typography className={classes.totalRatingText}>/5</Typography>
              <Typography
                style={{ fontSize: "0.875rem", marginLeft: "3px" }}
                className={classes.totalRatingText}
              >
                {headerData.averageTotal}
              </Typography>
            </Box>
          </Container> */}
          <Container style={{ marginLeft: "0px" }}>
            <Box
              style={{
                display: "flex",
                padding: "15px 0px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                className={classes.categoriesStyle}
                style={{ paddingRight: "5px" }}
                align="center"
              >
                {t("deliver")} {headerData?.deliveryTime} {t("minute")}
              </Typography>
            </Box>
          </Container>
        </>
      )}
    </Box>
  );
}

export default React.memo(RestaurantHeader);
