import React from "react";
import { useLocation } from "react-router";
import LoginDesktopHeader from "./DesktopHeader";
import { useTranslation } from "react-i18next";
import { useRestaurant } from "../../../hooks";

const REGISTRATION_PATH = [
  "/login",
  "/new-login",
  "/registration",
  "/login-email",
  "/forgot-password",
  "/email-sent",
  "/phone-number",
  "/verify-email",
];

function Header({ showIcon = false }) {
  const location = useLocation();
  const { t } = useTranslation();
  const { data } = useRestaurant("671a15e490f655298a89d128", "tacos-locos");
  const headerData = {
    name: data?.restaurant?.name ?? "...",
    averageReview: data?.restaurant?.reviewData.ratings ?? "...",
    averageTotal: data?.restaurant?.reviewData.total ?? "...",
    isAvailable: data?.restaurant?.isAvailable ?? true,
    openingTimes: data?.restaurant?.openingTimes ?? [],
    deliveryTime: data?.restaurant?.deliveryTime ?? 40,
  };
  const TITLE = "Tacos locos";
  const infos = `${t("deliver")} ${headerData?.deliveryTime} ${t("minute")}`;
  const showCart = !REGISTRATION_PATH.includes(location.pathname);

  return (
    <LoginDesktopHeader
      showIcon={showIcon}
      title={TITLE}
      infos={infos}
      showCart={showCart}
    />
  );
}

export default React.memo(Header);
