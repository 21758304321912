/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useContext, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import { Header, LoginHeader } from "../../components/Header";
import UserContext from "../../context/User";
import Analytics from "../../utils/analytics";
import useStyle from "./styles";

function Terms() {
  useEffect(async () => {
    await Analytics.track(Analytics.events.NAVIGATE_TO_TERMS);
  }, []);
  const classes = useStyle();
  const { isLoggedIn } = useContext(UserContext);
  return (
    <Grid className={classes.root}>
      {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
      <Grid container className={classes.mainContainer}>
        <Box className={classes.imageContainer}>
          <Typography
            variant="h4"
            color="textPrimary"
            align="center"
            className={classes.title}
          >
            TERMES ET CONDITIONS | <b>TOCOS LOCOS</b>
          </Typography>
        </Box>
        <Grid container item xs={12} className={classes.mainContainer}>
          <Grid item xs={1} md={1} />
          <Grid container item xs={10} sm={10} md={9}>
            <ol className={classes.boldText}>
              <Typography variant="subtitle2" className={classes.MV3}>
                Published: 16 Novembre 2024
              </Typography>
              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  CONDITIONS D'UTILISATION
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  Les présentes Conditions d'utilisation (« <b>Conditions</b> »)
                  régissent votre utilisation des sites Web et des applications
                  mobiles fournis par <b>Tacos Locos</b> (collectivement les « 
                  <b>Plateformes</b> »). Veuillez lire attentivement ces
                  Conditions. En accédant aux Plateformes et en les utilisant,
                  vous reconnaissez avoir lu, compris et accepté les Conditions
                  , y compris toutes les conditions générales supplémentaires et
                  toutes les politiques référencées ici, disponibles sur les
                  Plateformes ou disponibles par hyperlien. Si vous n'êtes pas
                  d'accord ou ne respectez pas les Conditions, n'utilisez pas
                  les Plateformes.
                </Typography>
                <Typography variant="subtitle2">
                  Les plateformes peuvent être utilisées par (i) des personnes
                  physiques ayant atteint l'âge de 18 ans et (ii) des personnes
                  morales, par exemple des sociétés. Le cas échéant, les
                  présentes Conditions seront soumises aux dispositions
                  spécifiques à chaque pays telles qu'énoncées dans les
                  présentes.
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  Les utilisateurs de moins de 18 ans doivent obtenir le
                  consentement de leurs parents ou tuteurs légaux, qui, en
                  acceptant les présentes Conditions, acceptent d'assumer la
                  responsabilité de leurs actes et de tous frais associés à leur
                  utilisation des Plateformes et/ou à l'achat de Articles. Si
                  vous n'avez pas le consentement de vos parents ou tuteurs
                  légaux, vous devez cesser immédiatement d'utiliser/d'accéder
                  aux Plateformes.
                </Typography>
                <Typography variant="subtitle2">
                  <b>Tacos Locos</b> se réserve le droit de modifier ou de
                  modifier les présentes Conditions (y compris nos politiques
                  qui sont intégrées dans ces Conditions) à tout moment. Il vous
                  est fortement recommandé de lire régulièrement ces Conditions.
                  Vous serez réputé avoir accepté les Conditions modifiées par
                  votre utilisation continue des Plateformes après la date à
                  laquelle les Conditions modifiées sont publiées.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  <b>Tacos Locos</b>
                </Typography>
                <ul className={classes.bullet}>
                  <li>
                    <Typography variant="subtitle2">
                      Ce que nous faisons
                    </Typography>
                    <Typography variant="subtitle2" className={classes.MV2}>
                      Grâce à nos plateformes, <b>Tacos Locos</b> vous met a
                      votre disposition une variété de produits pour vous
                      permettre de commander, y compris des plats préparés, des
                      tacos, pizzas, sandwichs, burgers, assiettes et desserts,
                      burgers, desserts et boissons à vous livrer. Lorsque vous
                      passez une commande de produits, <b>Tacos Locos</b> des
                      agents recoit, traite et valide la commande et ensuite
                      pour vous preparer votre commande et la passer a nos
                      livreurs afin de la consomer.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Comment nous contacter
                    </Typography>
                    <Typography variant="subtitle2" className={classes.MV2}>
                      Pour l'assistance client, vous pouvez nous contacter par
                      e-mail{" "}
                      <a href="mailto:contact@tacos-locos.fr">
                        contact@tacos-locos.fr
                      </a>{" "}
                      ou via téléphone : 03 89 88 66 03.{" "}
                    </Typography>
                  </li>
                </ul>
              </li>
              <li>
                <Typography
                  variant="subtitle2"
                  className={clsx(classes.boldText, classes.MV3)}
                >
                  Utilisation des Plateformes et du Compte <b>Tacos Locos</b>
                </Typography>
                <Typography variant="subtitle2">
                  Vous devrez créer un compte <b>Tacos Locos</b> pour pouvoir
                  utiliser la Plateforme. Lorsque vous créez un compte{" "}
                  <b>Tacos Locos</b>, nous vous demanderons de fournir vos
                  informations personnelles, notamment une adresse e-mail
                  valide, un numéro de téléphone portable et un mot de passe
                  unique. Pour acheter une Commande, selon le mode de paiement
                  que vous choisissez, vous devrez peut-être nous fournir les
                  détails de votre carte de crédit. Votre mot de passe unique ne
                  doit être partagé avec personne et vous acceptez de le garder
                  secret à tout moment. Vous êtes seul responsable de la
                  sécurité de votre mot de passe. Sauf en cas de fraude ou
                  d'abus qui ne sont pas de votre faute, vous acceptez que
                  toutes les commandes passées sous votre compte{" "}
                  <b>Tacos Locos</b> relèvent de votre seule responsabilité.
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  <b>Tacos Locos</b> ne sera pas responsable des commandes qui
                  rencontrent des problèmes de livraison en raison
                  d'informations incomplètes, incorrectes ou manquantes fournies
                  par vous. Vous êtes tenu de fournir des informations
                  complètes, exactes et véridiques pour le bon traitement de la
                  commande, y compris votre adresse de livraison et vos
                  coordonnées.
                </Typography>
                <Typography variant="subtitle2">
                  Si vous souhaitez supprimer votre compte <b>Tacos Locos</b>,
                  veuillez nous envoyer un e-mail pour le demander. Nous pouvons
                  restreindre, suspendre ou résilier votre compte{" "}
                  <b>Tacos Locos</b> et/ou l'utilisation des Plateformes, si
                  nous pensons raisonnablement que :
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  quelqu'un d'autre que vous utilise votre compte{" "}
                  <b>Tacos Locos</b> ; ou
                </Typography>
                <Typography variant="subtitle2">
                  lorsque vous êtes soupçonné ou découvert comme ayant été
                  impliqué dans toute activité ou conduite qui enfreint les
                  présentes Conditions, nos politiques et directives, ou
                  impliqué dans une activité ou une conduite que nous
                  considérons, à notre seule discrétion, comme un abus des
                  Plateformes.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="subtitle2"
                  className={clsx(classes.boldText, classes.MV3)}
                >
                  Restrictions
                </Typography>
                <Typography variant="subtitle2">
                  Activités interdites sur les plateformes
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  Lors du traitement de votre commande, nous pouvons envoyer vos
                  informations à des agences de référence de crédit et de
                  prévention de la fraude.
                </Typography>
                <Typography variant="subtitle2">
                  Voici une liste non exhaustive des types de conduite qui sont
                  illégales ou interdites sur les plateformes.{" "}
                  <b>Tacos Locos</b>
                  se réserve le droit d'enquêter et de prendre les mesures
                  juridiques appropriées contre toute personne qui, à la seule
                  discrétion de <b>Tacos Locos</b>, se livre à l'une des
                  activités interdites. Les activités interdites comprennent,
                  sans toutefois s'y limiter, les suivantes :
                </Typography>
                <ul className={classes.bullet}>
                  <li>
                    <Typography variant="subtitle2">
                      utiliser les plateformes à toute fin en violation des lois
                      ou réglementations locales, étatiques ou fédérales ;
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      publier tout contenu qui enfreint les droits de propriété
                      intellectuelle, les droits à la vie privée, les droits de
                      publicité, les droits au secret commercial ou tout autre
                      droit d'une partie ;
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      publier du contenu illégal, obscène, diffamatoire,
                      menaçant, harcelant, abusif, calomnieux, haineux ou
                      embarrassant pour toute autre personne ou entité, tel que
                      déterminé par <b>Tacos Locos</b> à sa seule discrétion ou
                      conformément aux normes de la communauté locale ;
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      publier du contenu qui constitue de la cyberintimidation,
                      tel que déterminé par <b>Tacos Locos</b> à sa seule
                      discrétion ;
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      publier du contenu qui décrit un comportement dangereux,
                      mettant la vie en danger ou autrement risqué ;
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      publier des numéros de téléphone, des adresses postales ou
                      des noms de famille de toute personne ;
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      publier des URL vers des sites Web externes ou toute forme
                      de code HTML ou de programmation ;
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      publier tout ce qui pourrait être du « spam », tel que
                      déterminé par
                      <b>Tacos Locos</b> à sa seule discrétion ;
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      se faire passer pour une autre personne lors de la
                      publication de contenu ;
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      récolter ou collecter de toute autre manière des
                      informations sur d'autres personnes, y compris des
                      adresses e-mail, sans leur consentement ;
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      permettre à toute autre personne ou entité d'utiliser
                      votre identification pour publier ou consulter des
                      commentaires ;
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      harceler, menacer, traquer ou abuser de toute personne sur
                      les Plateformes ;
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      adopter toute autre conduite qui restreint ou empêche
                      toute autre personne d'utiliser ou de profiter des sites
                      Web, ou qui, à la seule discrétion de <b>Tacos Locos</b>,
                      expose <b>Tacos Locos</b>
                      ou l'un de ses clients, fournisseurs ou toute autre partie
                      à toute responsabilité ou préjudice de quelque type que ce
                      soit ; ou
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      encourager d'autres personnes à s'engager dans des
                      activités interdites telles que décrites dans les
                      présentes.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      <b>Tacos Locos</b> se réserve le droit, mais n'est pas
                      obligé d'effectuer tout ou partie des actions suivantes :
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      enquêter sur une allégation selon laquelle tout contenu
                      publié sur les Plateformes n'est pas conforme aux
                      présentes Conditions et déterminer, à sa seule discrétion,
                      de supprimer ou de demander la suppression du contenu ;
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      supprimer tout contenu abusif, illégal ou perturbateur, ou
                      qui ne respecte pas les présentes Conditions ;
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      suspendre ou résilier l'accès d'un utilisateur aux
                      Plateformes ou à son compte <b>Tacos Locos</b> en cas de
                      violation des présentes Conditions ;
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      surveiller, modifier ou divulguer tout contenu sur les
                      plateformes ; et
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      modifier ou supprimer tout contenu publié sur les
                      Plateformes, que ce contenu viole ou non ces normes.
                    </Typography>
                  </li>
                </ul>
              </li>
              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Propriété intellectuelle
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  Toutes les marques commerciales, logos, images et marques de
                  service, y compris ces Conditions telles qu'affichées sur les
                  Plateformes ou dans nos supports marketing, qu'ils soient
                  enregistrés ou non, sont la propriété intellectuelle de{" "}
                  <b>Tacos Locos</b> et/ou de tiers qui nous ont autorisé à les
                  utiliser (collectivement les « <b>Marques commerciales</b> »).
                  Vous ne pouvez pas utiliser, copier, reproduire, rééditer,
                  télécharger, publier, transmettre, distribuer ou modifier ces
                  marques de quelque manière que ce soit sans notre consentement
                  écrit exprès préalable. L'utilisation des marques de commerce
                  de <b>Tacos Locos</b> sur tout autre site Web non approuvé par
                  nous est strictement interdite. <b>Tacos Locos</b> fera valoir
                  agressivement ses droits de propriété intellectuelle dans
                  toute la mesure permise par la loi, y compris les poursuites
                  pénales. <b>Tacos Locos</b> ne garantit ni ne déclare que
                  votre utilisation des éléments affichés sur les plateformes ne
                  violera pas les droits de tiers non détenus ou affiliés à{" "}
                  <b>Tacos Locos</b>. L'utilisation de tout élément sur les
                  plateformes est à vos propres risques.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Restrictions sur les produits
                </Typography>
                <ul className={classes.bullet}>
                  <li>
                    <Typography variant="subtitle2">
                      Certains des produits que nous proposons sur nos
                      plateformes sont soumis à des restrictions d'achat («
                      <b>Produits soumis à des restrictions</b> »)
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Alcool / Produits alcoolisés (« <b>Alcool</b> »){" "}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.MV2}>
                      Pour acheter de l'alcool, vous devez avoir l'âge légal
                      <b>Tacos Locos</b>, le Vendeur et ses livreurs, selon le
                      cas, se réservent le droit, à leur seule discrétion :
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      de demander une preuve d'âge valide (par exemple une carte
                      d'identité) à toute personne avant de livrer de l'alcool ;
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      de refuser la livraison si vous n'êtes pas en mesure de
                      prouver que vous avez l'âge légal ; et/ou
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      de refuser la livraison à toute personne pour quelque
                      raison que ce soit.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Cigarettes/Produits du tabac (« <b>Tabac</b> »)
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Nous pouvons proposer du tabac sur certaines de nos
                      plateformes lorsque les lois le permettent. En proposant
                      du tabac à la vente sur nos plateformes, nous ne
                      prétendons pas faire de la publicité, promouvoir ou
                      encourager l'achat ou la consommation de tabac de quelque
                      manière que ce soit.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Pour acheter du tabac, vous devez avoir l'âge légal légal.{" "}
                      <b>Tacos Locos</b>, le vendeur et ses livreurs, selon le
                      cas, se réservent le droit, à leur seule discrétion :
                    </Typography>
                    <ul className={classes.circle}>
                      <li>
                        <Typography variant="subtitle2">
                          to ask for valid proof of age (e.g. ID card) to any
                          persons before they deliver Tobacco;
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="subtitle2">
                          to refuse delivery if you are unable to prove you are
                          of legal age; and/or
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="subtitle2">
                          to refuse delivery to any persons for any reason
                          whatsoever.
                        </Typography>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Any offer for any Alcohol and Tobacco made on the
                      Platforms is void when it is prohibited by law.
                    </Typography>
                  </li>
                </ul>
              </li>
              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Orders
                </Typography>
                <ul className={classes.bullet}>
                  <li>
                    <Typography variant="subtitle2">
                      When you place an Order with <b>Tacos Locos</b>,{" "}
                      <b>Tacos Locos</b> will confirm your order by sending you
                      a confirmation email containing the Order receipt. Where
                      applicable, Orders will include delivery fees and any
                      applicable tax (e.g. goods and services tax, value-added
                      tax, etc.).
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Minimum Order Value - Some of our Vendors require a
                      minimum order value (“<b>MOV</b>”) before an Order can be
                      placed and delivered to you. Where an applicable Order
                      fails to meet the MOV, you will have the option of paying
                      the difference to meet the MOV or to add more Goods to
                      your Order.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Special Instructions – <b>Tacos Locos</b> and the Vendor
                      (as the case may be) reasonably endeavour to comply with
                      your special instructions for an Order. However in some
                      cases where this is not feasible, possible or commercially
                      reasonable,
                      <b>Tacos Locos</b> and/or the Vendor reserve the right to
                      proceed to prepare the Order in accordance with standard
                      operating procedures. Neither <b>Tacos Locos</b> nor the
                      Vendor shall be responsible to replace or refund an Order
                      which does not conform to special instructions provided by
                      you.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Allergens – <b>Tacos Locos</b> is not obligated to provide
                      ingredient information or allergen information on the
                      Platforms. Further, <b>Tacos Locos</b> does not guarantee
                      that the Goods sold by Vendors are free of allergens. If
                      you have allergies, allergic reactions or dietary
                      restrictions and requirements, please contact the Vendor
                      before placing an Order on our Platforms.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Please note that your Order may be subject to additional
                      terms and conditions provided by the Vendor.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Prior to placing the Order
                    </Typography>
                    <ul className={classes.circle}>
                      <li>
                        <Typography variant="subtitle2">
                          You are required to provide the delivery address in
                          order for the Platform to display the Vendors
                          available in your delivery area.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="subtitle2">
                          Once you select a Vendor, you will be taken to that
                          Vendor’s menu page for you to select and add your
                          Goods to the cart.
                        </Typography>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Placing the Order
                    </Typography>
                    <ul className={classes.circle}>
                      <li>
                        <Typography variant="subtitle2">
                          To complete an Order, please follow the onscreen
                          instructions after clicking ‘Checkout’. You may be
                          required to provide additional details for us to
                          complete your Order. You are required to review and
                          confirm that all the information you provide,
                          including the amounts, delivery details, personal
                          details, payment information, and voucher codes (if
                          applicable) is true, accurate and complete before you
                          click “PLACE ORDER”. An Order is successfully placed
                          when you receive an email confirmation containing your
                          Order receipt from us.
                        </Typography>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Cancelling an Order
                    </Typography>
                    <ul className={classes.circle}>
                      <li>
                        <Typography variant="subtitle2">
                          Please contact us immediately via our in-app customer
                          support chat feature if you wish to cancel your Order
                          after it has been placed. You have the right to cancel
                          your Order provided a Vendor has not yet accepted your
                          Order.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="subtitle2">Refunds</Typography>
                        <ul>
                          <li>
                            <Typography variant="subtitle2">
                              Online Payment Orders
                            </Typography>
                            <Typography variant="subtitle2">
                              You have the right to a refund for a cancelled
                              Order only if a Vendor has not yet accepted your
                              Order. Should you still decide to cancel your
                              Order after it has been accepted by the Vendor,
                              you understand that no refunds (whether in whole
                              or in part) will be issued to you and you forfeit
                              the delivery of your cancelled Order.
                            </Typography>
                          </li>
                          <li>
                            <Typography variant="subtitle2">
                              Cash-on-Delivery Orders{" "}
                            </Typography>
                            <Typography variant="subtitle2">
                              You have the right to cancel your Order only if a
                              Vendor has not yet accepted your Order. Should you
                              still decide to cancel your Order after it has
                              been accepted by the Vendor, you understand that
                              you shall forfeit the delivery of your cancelled
                              Order and cash-on-delivery may be removed from
                              your list of available payment methods for your
                              future orders.
                            </Typography>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      <b>Tacos Locos</b> reserves the right to cancel any Order
                      and/or suspend, deactivate or terminate your{" "}
                      <b>Tacos Locos</b> account in its sole discretion if it
                      reasonably suspects or detects fraudulent behavior or
                      activity associated with your
                      <b>Tacos Locos</b> account and/or with your Order.{" "}
                    </Typography>
                  </li>
                </ul>
              </li>
              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Prices and Payments
                </Typography>
                <ul className={classes.bullet}>
                  <li>
                    <Typography variant="subtitle2">
                      Prices quoted on the Platform shall be displayed in the
                      applicable country’s national currency and subject to
                      applicable tax. Prices and offers on the Platforms may
                      vary from the prices and you accept that offers offered by
                      our Vendors (either on their own websites, mobile
                      applications, or at their brick-and-mortar outlets).
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      include TAX, VAT or such other equivalent tax; or
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      exclude TAX, VAT or such other equivalent tax.
                    </Typography>
                    <Typography variant="subtitle2" className={classes.MV2}>
                      A breakdown of the prices and additional charges are
                      displayed before Checkout. When you place an Order, you
                      agree to all amounts, additional charges and the final
                      ‘Total’ amount which is displayed to you.{" "}
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Delivery fees are chargeable on every Order unless:
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      you opt to collect your Order directly from the Vendor (“
                      <b>Pick-Up</b>”);
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      you have a valid promotional or discount voucher and apply
                      it at Checkout; or
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      unless stated otherwise.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Prices indicated on the Platforms are as at the time of
                      each Order and may be subject to change.
                    </Typography>
                    <li>
                      <Typography variant="subtitle2">
                        You can choose to pay for an Order using any of the
                        different payment methods offered on the Platforms
                        including:
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="subtitle2">
                        Our payment partners: Visa, Mastercard, American
                        Express, Google Pay, PayPal, Apple Pay;
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="subtitle2">
                        Cash-on-Delivery; or
                      </Typography>
                    </li>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Such other payment method we offer from time to time.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      If you have existing credit in your <b>Tacos Locos</b>{" "}
                      account or valid promotional or discount vouchers, you can
                      use this pay for part or all of your Order as the case may
                      be.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      After an Order is successfully placed, you will receive an
                      email confirmation from us with your Order receipt.
                      Delivery fees will not appear in your Order receipt if you
                      opt for Pick-Up.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">Payment Methods</Typography>
                    <Typography variant="subtitle2" className={classes.MV2}>
                      <b>Tacos Locos</b> reserves the right to offer additional
                      payment methods and/or remove existing payment methods at
                      anytime in its sole discretion. If you choose to pay using
                      an online payment method, the payment shall be processed
                      by our third party payment service provider(s). With your
                      consent, your credit card / payment information will be
                      stored with our third party payment service provider(s)
                      for future orders. <b>Tacos Locos</b> does not store your
                      credit card or payment information.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      You must ensure that you have sufficient funds on your
                      credit and debit card to fulfil payment of an Order.
                      Insofar as required, <b>Tacos Locos</b> takes
                      responsibility for payments made on our Platforms
                      including refunds, chargebacks, cancellations and dispute
                      resolution, provided if reasonable and justifiable and in
                      accordance with these Terms.
                    </Typography>
                  </li>
                </ul>
              </li>
              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Delivery, Pick-Up and Vendor Delivery
                </Typography>
                <ul className={classes.bullet}>
                  <li>
                    <Typography variant="subtitle2">Delivery Areas</Typography>
                    <Typography variant="subtitle2" className={classes.MV2}>
                      You understand that our Vendors offer their Goods in
                      specific delivery areas and our Vendors vary from delivery
                      area to delivery area. By entering your delivery address
                      on the Platforms, you will see the Vendors that we make
                      available to you at that time. Delivery areas may expand,
                      shrink or change depending on weather and traffic
                      conditions and situations of force majeure.{" "}
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">Delivery Time</Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      <b>Tacos Locos</b> shall deliver your Order to the
                      delivery address provided by You. You may choose for your
                      Order to be delivered “ASAP” or scheduled for a specific
                      time. An estimated delivery time will be provided to you
                      in your email confirmation but delivery times shall vary
                      depending on factors that are not controlled by us (e.g.
                      order quantity, distance, time of day (peak periods),
                      weather conditions, traffic conditions, etc.). You can
                      view the remaining delivery time of an Order when you
                      click on ‘My orders’ on the Platforms. You acknowledge
                      that the delivery time we provide is only an estimate and
                      Orders may arrive earlier or later. To ensure that you do
                      not miss a delivery of an Order, you should ensure that
                      either you or someone is at the delivery location to
                      receive the Order once an Order is placed. If your Order
                      contains Alcohol or Tobacco (if applicable) and you or the
                      recipient is or appears to be below the legal age, or
                      fails to provide a valid proof of ID, <b>Tacos Locos</b>{" "}
                      reserves the right not to deliver your Order to you.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Unsuccessful or Failed Deliveries{" "}
                    </Typography>
                  </li>
                  <ul className={classes.circle}>
                    <li>
                      <Typography variant="subtitle2">
                        In cases where we attempt to deliver an Order but we are
                        unable to do so due to the reasons caused by you,
                        including but not limited to:
                      </Typography>
                      <ul>
                        <li>
                          <Typography variant="subtitle2">
                            no one was present or available to receive the
                            Order; or
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="subtitle2">
                            customer was uncontactable despite attempts to reach
                            the customer via the phone number provided; or
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="subtitle2">
                            lack of appropriate or sufficient access to deliver
                            the Order successfully;
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="subtitle2">
                            lack of a suitable or secure location to leave the
                            Order; or
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="subtitle2">
                            in the case of Restricted Goods, customer did not
                            meet the statutory age requirements or delivery did
                            not deem it safe or appropriate for the customer to
                            receive the Restricted Goods.
                          </Typography>
                        </li>
                      </ul>
                      <li>
                        <Typography variant="subtitle2">
                          No-show Cancellations
                        </Typography>
                        <Typography variant="subtitle2" className={classes.MV2}>
                          If you remain uncontactable or fail to receive the
                          Order within ten (10) minutes from the time the Order
                          arrives at your delivery address, <b>Tacos Locos</b>{" "}
                          reserves the right to cancel the Order without refund
                          or remedy to you.
                        </Typography>
                      </li>
                    </li>
                  </ul>
                  <li>
                    <Typography variant="subtitle2">
                      Wrong Order, Missing Items, Defective Goods
                    </Typography>
                    <Typography variant="subtitle2" className={classes.MV2}>
                      Upon receipt of your Order, if you discover that there are
                      issues with your Order (e.g. wrong order, defective order,
                      or missing items) please contact customer support via one
                      of the methods indicated in Clause 1.3 above immediately.
                      In some cases, <b>Tacos Locos</b> may request for
                      photographic proof and/or additional information to
                      properly investigate the issue with your Order. If we
                      determine that the Order and/or Goods you received are not
                      of satisfactory condition or quality, we will compensate
                      you for your Order or parts of your Order.
                    </Typography>
                    <li>
                      <Typography variant="subtitle2">Order Pick-Up</Typography>
                    </li>
                    <ul className={classes.circle}>
                      <li>
                        <Typography variant="subtitle2">
                          Where available, you will have the option of
                          collecting your Order in-person directly from the
                          Vendor’s premises ("<b>Pick-Up</b>") instead of having
                          the Order delivered to You. Your email confirmation
                          will indicate the time for you to Pick-Up the Order
                          (“Collection Time”). The Vendor will prepare the Order
                          by the Collection Time. In some cases, a reasonable
                          delay may be expected. The Vendor agrees to hold the
                          Order for you at the Vendor’s premises for no more
                          than a reasonable period of twenty (20) minutes from
                          the Collection Time (“<b>Holding Time</b>
                          ”) and shall not be obliged to provide the Order to
                          you if you fail to Pick-Up your Order within the
                          Holding Time.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="subtitle2">
                          In the event of unreasonable delays in Pick-Up
                          attributable to you, you bear the risk of any damage
                          or loss of Goods or any deterioration in quality or
                          change in condition of the Goods (e.g. changes in the
                          temperature fit for consumption). In this case, you
                          shall not be entitled to a replacement, refund or
                          replacement of the Goods. You alone are responsible
                          for inspecting the Goods/Order when you Pick-Up your
                          Order and shall report any issues and/or defects to
                          the Vendor before leaving the Vendor’s premises.
                        </Typography>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typography variant="subtitle2">Vendor Delivery</Typography>
                    <Typography variant="subtitle2" className={classes.MV2}>
                      In some cases, our Vendors will deliver the Order to you
                      (“Vendor Delivery”). While we will use reasonable efforts
                      to provide prior notice to you on Vendor Delivery, this
                      may not always be possible. Where Vendor Delivery applies,
                      we may ask you to contact the Vendor directly in the event
                      of issues or delays in your delivery. <b>Tacos Locos</b>{" "}
                      shall not be responsible in any way for Orders or Goods
                      that are delivered by Vendors.{" "}
                    </Typography>
                  </li>
                </ul>
              </li>
              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Vouchers, Discounts and Promotions
                </Typography>
                <ul className={classes.bullet}>
                  <li>
                    <Typography variant="subtitle2">
                      From time to time, <b>Tacos Locos</b> may run marketing
                      and promotional campaigns which offer voucher codes,
                      discounts, and other promotional offers to be used on the
                      Platforms (“<b>Vouchers</b>”). Vouchers are subject to
                      validity periods, redemption periods, and in certain
                      cases, may only be used once.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Unless otherwise stated, Vouchers can only be used on our
                      Platforms.{" "}
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Vouchers cannot be exchanged for cash.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      <b>Tacos Locos</b> reserves the right to void, discontinue
                      or reject the use of any Voucher without prior notice
                      Individual restaurants terms & conditions apply
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      We may exclude certain Vendors from the use of Vouchers at
                      any time without prior notice to you.
                    </Typography>
                  </li>
                </ul>
              </li>
              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Representations, Warranties and Limitation of Liabilities
                </Typography>
                <ul className={classes.bullet}>
                  <li>
                    <Typography variant="subtitle2">
                      Representations and Warranties
                    </Typography>
                    <Typography variant="subtitle2" className={classes.MV2}>
                      You acknowledge and agree that the content on the
                      Platforms are provided on an “as is” and “as available”
                      basis, and that your use of or reliance upon the Platforms
                      and any content, goods, products or services accessed or
                      obtained thereby is at your sole risk and discretion.
                      While <b>Tacos Locos</b> makes reasonable efforts to
                      ensure the provision of the Platforms and the services we
                      offer, are available at all times, we do not warrant or
                      represent that the Platforms shall be provided in a manner
                      which is secure, timely, uninterrupted, error-free, free
                      of technical difficulties, defects or viruses. Please
                      expect temporary interruptions of the Platform due to
                      scheduled or regular system maintenance work, downtimes
                      attributable to internet or electronic communications or
                      events of force majeure.
                    </Typography>
                    <Typography variant="subtitle2">
                      Limitation of Liability
                    </Typography>
                    <Typography variant="subtitle2" className={classes.MV2}>
                      To the extent permitted by law, <b>Tacos Locos</b> (which
                      shall include its employees, directors, agents,
                      representatives, affiliates and parent company) exclude
                      all liability (whether arising in contract, in negligence
                      or otherwise) for loss or damage which you or any third
                      party may incur in connection with our Platforms, our
                      services, and any website linked to our Platforms and any
                      content or material posted on it. Your exclusive remedy
                      with respect to your use of the Platforms is to
                      discontinue your use of the Platforms. The{" "}
                      <b>Tacos Locos</b> entities, their agents,
                      representatives, and service providers shall not be liable
                      for any indirect, special, incidental, consequential, or
                      exemplary damages arising from your use of the Platforms
                      or for any other claim related in any way to your use of
                      the Platforms. These exclusions for indirect, special,
                      consequential, and exemplary damages include, without
                      limitation, damages for lost profits, lost data, loss of
                      goodwill, work stoppage, work stoppage, computer failure,
                      or malfunction, or any other commercial damages or losses,
                      even if the <b>Tacos Locos</b> entities, their agents,
                      representatives, and service providers have been advised
                      of the possibility thereof and regardless of the legal or
                      equitable theory upon which the claim is based. Because
                      some states or jurisdictions do not allow the exclusion or
                      the limitation of liability for consequential or
                      incidental damages, in such states or jurisdictions,
                      <b>Tacos Locos</b>, the <b>Tacos Locos</b> entities, its
                      agents, representatives and service providers' liability
                      shall be limited to the extent permitted by law.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Vendor’s representations
                    </Typography>
                    <Typography variant="subtitle2" className={classes.MV2}>
                      <b>Tacos Locos</b> shall neither be liable for actions or
                      omissions of the Vendor nor you in regard to provision of
                      the Goods and where Vendor Delivery applies to your Order.
                      <b>Tacos Locos</b> does not assume any liability for the
                      quantity, quality, condition or other representations of
                      the Goods and/or services provided by Vendors or guarantee
                      the accuracy or completeness of the information (including
                      menu information, photos and images of the Goods)
                      displayed on the Vendor’s listing/offering on the
                      Platform. Nothing in these Terms shall exclude Vendor’s
                      liability for death or personal injury arising from
                      Vendor’s gross negligence or willful misconduct.
                    </Typography>
                  </li>
                </ul>
              </li>
              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Vendor Liability
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  Vendors are responsible for the preparation, condition and
                  quality of Goods. In cases of Vendor Delivery, Vendors are
                  responsible for delivery of the Goods and/or Orders. Tacos
                  Locos shall not be liable for any loss or damage arising from
                  your contractual relationship with the Vendor.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Personal Data (Personal Information) Protection
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  You agree and consent to <b>Tacos Locos</b> and any of its
                  affiliate companies collecting, using, processing and
                  disclosing your Personal Data in accordance with these Terms
                  and as further described in our Privacy Policy. Our Privacy
                  Policy is available via the links on our Platforms, and shall
                  form a part of these Terms.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Indemnity
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  You agree to indemnify, defend, hold harmless{" "}
                  <b>Tacos Locos</b>, its directors, officers, employees,
                  representatives, agents, and affiliates, from any and all
                  third party claims, liability, damages and/or costs (including
                  but not limited to, legal fees) arising from your use of the
                  Platforms or your breach of these Terms.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Third Party Links and Websites
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  The Platforms may contain links to other third party websites
                  and by clicking on these links, you agree to do so at your own
                  risk. <b>Tacos Locos</b> does not control or endorse these
                  third party websites or links and shall not be responsible for
                  the content of these linked pages. <b>Tacos Locos</b> accepts
                  no liability or responsibility for any loss or damage which
                  may be suffered by you in relation to your access and use of
                  these third party links and websites.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Termination
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  <b>Tacos Locos</b> has the right to terminate, suspend or
                  delete your account and access to the Platforms, including any
                  delivery service we provide to you in respect of an Order, for
                  any reason, including, without limitation, if{" "}
                  <b>Tacos Locos</b>, in its sole discretion, considers your use
                  to be unacceptable, or in the event of any breach by you of
                  the Terms. <b>Tacos Locos</b> may, but shall be under no
                  obligation to, provide you a warning prior to termination of
                  your use of the Websites.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Amendments
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  <b>Tacos Locos</b> may amend these Terms at any time in its
                  sole discretion. The amended Terms shall be effective
                  immediately upon posting and you agree to the new Terms by
                  continued use of the Platforms. It is your responsibility to
                  check the Terms regularly. If you do not agree with the
                  amended Terms, whether in whole or in part, you must stop
                  using the Platforms immediately.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Severability
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  If any provision of these Terms of Use is found to be invalid
                  by any court having competent jurisdiction, the invalidity of
                  such provision shall not affect the validity of the remaining
                  provisions of these Terms of Use, which shall remain in full
                  force and effect. No waiver of any provision in these Terms of
                  Use shall be deemed a further or continuing waiver of such
                  provision or any other provision.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Governing Law
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  These Terms shall be governed and construed in accordance with
                  the laws of the country / courts of jurisdiction.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Contact Us
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  If you wish to contact us regarding any questions or comments
                  you may have, please send an email to our customer support
                  email or via our in-app customer support chat feature.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Prevailing Language
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  In the event of a dispute as to the Terms, the English version
                  shall prevail. The English language version of these Terms
                  shall control in all respects and shall prevail in case of any
                  inconsistencies with translated versions.
                </Typography>
              </li>
            </ol>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
}

export default React.memo(Terms);
